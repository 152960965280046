import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import demo0 from "../../assets/images/demo/1447_b.jpeg";

import OrderConstant from "../../constants/OrderConstant";

import {
  getStorageList as getStorageListAction,
  getStorageListByCat as getStorageListByCatAction,
  addStorage as addStorageAction,
  update as updateStorageAction,
  searchStorage as searchStorageAction
} from "../../redux/modules/storage";

import {
  convertImages,
  unCheckInArray,
  inArrayValueByKey,
  toArrayKeyLabelListKey,
} from "../../utilities/array";

import UiInputSearch from "../../components/inputs/UiInputSearch";
import UiButtonColor from "../../components/button/UiButtonColor";
import UiDropDown from "../../components/inputs/UiDropDown";
import UiPageText from "../../components/text/UiPageText";
import UiTableAdaptive from "../../components/table/UiTableAdaptive";

import "./ProductsStyle.scss";
import Env from "../../services/Env";
import { storeData } from "../../services/Storage";
import AppConstant from "../../constants/AppConstant";
import { parseCityName } from "../../utilities/order";

function ProductsPage(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [modalDealAddACtive, setModalDealAddActive] = useState(false);
  const [searchLine, setSearchLine] = useState("");
  const [categoriesList, setCategoriesList] = useState([]);

  const clientsTitles = [
    { sort: false, title: "" },
    { sort: false, title: "" },
    { sort: false, title: "" },
    { sort: true, title: "Города" },

    { sort: true, title: "Артикул" },
    { sort: false, title: "Название" },
    { sort: true, title: "Категория" },
    { sort: true, title: "Цена" },
    { sort: true, title: "В слайдер" },
    { sort: true, title: "Центр слайдера" },
    { sort: false, title: "Видимость" },
    { sort: false, title: "Удалить" },
  ];
  const [tableListOrders, setTableListOrders] = useState([]);

  function _load() {
    if (id == "all") props.getStorageList();
    else props.getStorageListByCat(id);
  }

  useEffect(() => {
    _load();
  }, [id]);

  useEffect(() => {
    if(props.category.list.length > 0) setCategoriesList(props.category.list);
  }, [props.category]);

  useEffect(() => {
    let arr = [];
    props.storage.list.map((item) => {
      let images = convertImages(item.imgs);
      item.images = images;
      arr.push([
        {
          button: "edit",
          onClick: () => {
            storeData("edit", item);
            navigate("/products/add");
          },
        },
        {
          inputSort: `${item.sort}`,
          onBlurInput: (v) => {
            let _item = item;
            let s = _item.sort;
            if (v == 1) {
              s++;
              _item.sort = s;
            } else {
              s--;
              _item.sort = s;
            }
            props.update(_item).then(() => _load());
          },
        },
        { image: images[0] ? Env.PUBLIC_URL + images[0] : null },

        {
          statusInfo: parseCityName(item.city),
          statusColor: OrderConstant.ORDER_TYPES_COLORS[2],
          statusList: AppConstant.APP_CITY,
          onStatusChange: (val) => {
            let _item = item;

            _item.city = unCheckInArray(_item.city.split(","), val).join(",");

            props.update(_item).then(() => _load());
          },
        },

        { text: item.id },
        { text: item.title },
        {
          statusInfo:
            props.category.list.length > 0
              ? item.category?.split(",").map((cItem) => {
                  return `${
                    inArrayValueByKey(props.category.list, "id", cItem)?.title
                  } , `;
                })
              : "",
          statusColor: "orange",
        },
        { text: `${item.price} ₽` },

        {
          text:  item.is_slider,
          button: item.is_slider ? "eye-open" : "eye", 
          buttonColor: item.is_slider ? "green" : "white", 
          onClick: () => {
            let _item = item;
            _item.is_slider = _item.is_slider == 1 ? 0 : 1;
            props.update(_item).then(() => _load());
          }
        },

        {
          text:  item.is_center,
          button:  "done", 
          buttonColor: item.is_center === 1 ? "green" : "white", 
          onClick: () => {
            let _item = item;
            _item.is_center = item.is_center == 1 ? 0 : 1;
            props.update(_item).then(() => _load());
          }
        },


        {
          button: item.is_active == 0 ? "eye-open" : "eye",
          onClick: () => {
            let _item = item;
            _item.is_active = item.is_active == 1 ? 0 : 1;
            props.update(_item).then(() => _load());
          },
        },
        {
          button: "delete",
          onClick: () => {
            if (window.confirm("Удалить элемент ?")) {
              let _item = item;
              _item.is_del = item.is_del == 1 ? 0 : 1;
              props.update(_item).then(() => _load());
            }
          },
        },
      ]);
    });
    setTableListOrders(arr);
  }, [props.storage.list, props.category.list]);

  useEffect(() => {
    if(searchLine != "") {
      props.searchStorage(searchLine);
    } else {
      _load()
    }
  }, [searchLine])

  return (
    <div id="page" className="home-page">
      <section className="home-info ui-grid">
        <div className="home-info-text">
          <UiPageText
            title={
              id !== "all"
                ? `Каталог товаров в категории: ${
                  categoriesList.length > 0
                      ? inArrayValueByKey(categoriesList, "id", id)?.title
                      : ""
                  }`
                : `Каталог товаров`
            }
          />
        </div>
        <div className="home-info-button">
          <UiButtonColor
            color="primary"
            text="Добавить"
            small={true}
            onClick={() => {
              storeData("edit", null);
              navigate("/products/add");
            }}
          />
        </div>
      </section>
      <section className="page-content">
        <div className="page-search">
          <UiInputSearch
            placeholder="Поиск:"
            onChange={(val) => setSearchLine(val)}
          />
        </div>

        <div className="page-search">
          <UiDropDown
            label="Фильтр по категории:"
            value={id}
            items={toArrayKeyLabelListKey(
              categoriesList ? categoriesList : [],
              "id",
              "title" , true
            )}
            onChange={(val) => { 
               navigate(val  ? `/products/category/${val}` : `/products/category/all`)
            }}
          />
        </div>

        <UiTableAdaptive
          pageSize={100}
          titleList={clientsTitles}
          grid={clientsTitles.length}
          tableList={tableListOrders} 
        />
      </section>
    </div>
  );
}

export default connect(
  ({ storage, login, comment, logs, user, settings, category }) => ({
    storage,
    login,
    comment,
    logs,
    user,
    settings,
    category,
  }),
  {
    getStorageList: getStorageListAction,
    getStorageListByCat: getStorageListByCatAction,
    addStorage: addStorageAction,
    update: updateStorageAction,
    searchStorage: searchStorageAction
  }
)(ProductsPage);
