import React from "react";

import { ReactComponent as SvgAdd } from "../../assets/ui/add.svg";
import { ReactComponent as SvgArrowLeft } from "../../assets/ui/arrow-left.svg";
import { ReactComponent as SvgArrowRight } from "../../assets/ui/arrow-right.svg";
import { ReactComponent as SvgBag } from "../../assets/ui/bag.svg";
import { ReactComponent as SvgBagAdd } from "../../assets/ui/bag-add.svg";
import { ReactComponent as SvgCall } from "../../assets/ui/call.svg";
import { ReactComponent as SvgCaret } from "../../assets/ui/caret.svg";
import { ReactComponent as SvgClipboard } from "../../assets/ui/clipboard.svg";
import { ReactComponent as SvgClock } from "../../assets/ui/clock.svg";
import { ReactComponent as SvgClose } from "../../assets/ui/close.svg";
import { ReactComponent as SvgDown } from "../../assets/ui/select-down.svg";
import { ReactComponent as SvgInstagram } from "../../assets/social/instagram-color.svg";
import { ReactComponent as SvgMenu } from "../../assets/ui/menus.svg";
import { ReactComponent as SvgMessage } from "../../assets/ui/message.svg";
import { ReactComponent as SvgPin } from "../../assets/ui/pin.svg";
import { ReactComponent as SvgRemove } from "../../assets/ui/remove.svg";
import { ReactComponent as SvgScan } from "../../assets/ui/scan.svg";
import { ReactComponent as SvgTelegram } from "../../assets/social/telegram-fill.svg";
import { ReactComponent as SvgUp } from "../../assets/ui/select-up.svg";
import { ReactComponent as SvgUser } from "../../assets/ui/user.svg";
import { ReactComponent as SvgViber } from "../../assets/social/viber.svg";
import { ReactComponent as SvgVk } from "../../assets/social/vk-fill.svg";
import { ReactComponent as SvgWhatsapp } from "../../assets/social/whatsapp-fill.svg";

import "./UiIcon.scss";

const UiIcon = (props) => {
  const iconClass = "svg-icon" + (props.fill ? " " + props.fill : "");
  const iconStyle = { width: props.size, height: props.size };

  return props.icon === "add" ? (
    <SvgAdd className={iconClass} style={iconStyle} />
  ) : props.icon === "arrow-left" ? (
    <SvgArrowLeft className={iconClass} style={iconStyle} />
  ) : props.icon === "arrow-right" ? (
    <SvgArrowRight className={iconClass} style={iconStyle} />
  ) : props.icon === "bag" ? (
    <SvgBag className={iconClass} style={iconStyle} />
  ) : props.icon === "bag-add" ? (
    <SvgBagAdd className={iconClass} style={iconStyle} />
  ) : props.icon === "call" ? (
    <SvgCall className={iconClass} style={iconStyle} />
  ) : props.icon === "caret" ? (
    <SvgCaret className={iconClass} style={iconStyle} />
  ) : props.icon === "clipboard" ? (
    <SvgClipboard className={iconClass} style={iconStyle} />
  ) : props.icon === "clock" ? (
    <SvgClock className={iconClass} style={iconStyle} />
  ) : props.icon === "close" ? (
    <SvgClose className={iconClass} style={iconStyle} />
  ) : props.icon === "down" ? (
    <SvgDown className={iconClass} style={iconStyle} />
  ) : props.icon === "instagram" ? (
    <SvgInstagram className={iconClass} style={iconStyle} />
  ) : props.icon === "menu" ? (
    <SvgMenu className={iconClass} style={iconStyle} />
  ) : props.icon === "message" ? (
    <SvgMessage className={iconClass} style={iconStyle} />
  ) : props.icon === "pin" ? (
    <SvgPin className={iconClass} style={iconStyle} />
  ) : props.icon === "remove" ? (
    <SvgRemove className={iconClass} style={iconStyle} />
  ) : props.icon === "scan" ? (
    <SvgScan className={iconClass} style={iconStyle} />
  ) : props.icon === "telegram" ? (
    <SvgTelegram className={iconClass} style={iconStyle} />
  ) : props.icon === "up" ? (
    <SvgUp className={iconClass} style={iconStyle} />
  ) : props.icon === "user" ? (
    <SvgUser className={iconClass} style={iconStyle} />
  ) : props.icon === "viber" ? (
    <SvgViber className={iconClass} style={iconStyle} />
  ) : props.icon === "vk" ? (
    <SvgVk className={iconClass} style={iconStyle} />
  ) : props.icon === "whatsapp" ? (
    <SvgWhatsapp className={iconClass} style={iconStyle} />
  ) : null;
};
export default UiIcon;
