import React, { useEffect, useState } from "react";

import { ReactComponent as SvgAngle } from "../../assets/images/ui/arrow-sort.svg";
import { ReactComponent as SvgCheck } from "../../assets/images/ui/check2.svg";
import { ReactComponent as SvgDelete } from "../../assets/images/ui/delete.svg";
import { ReactComponent as SvgDelivery } from "../../assets/images/ui/scooter.svg";
import { ReactComponent as SvgEye } from "../../assets/images/ui/eye.svg";
import { ReactComponent as SvgEyeOpen } from "../../assets/images/ui/eye-open.svg";
import { ReactComponent as SvgEdit } from "../../assets/images/ui/pencil.svg";
import { ReactComponent as SvgOpen } from "../../assets/images/ui/search.svg";
import { ReactComponent as SvgWait } from "../../assets/images/ui/wait.svg";
import { ReactComponent as SvgWallet } from "../../assets/images/ui/wallet.svg";
import { ReactComponent as SvgArrowDown } from "../../assets/images/ui/arrow-select.svg";

import Env from "../../services/Env";

import "./UiTableAdaptiveCard.scss";

const UiInputCard = (props) => {
  const [state, setState] = useState(props.input);
  useEffect(()=>{
     setState(props.input);
  }, [props.input]);
  return (
    <input 
      style={{width: '40px', borderRadius: '5px', padding: '2px', border: '2px solid #222'}} 
      value={state} 
      type={'number'} 
      onBlur={()=>{
        props.onBlurInput(state)
      }}
      onChange={(e) => { 
        setState(e.target.value);
      }}
    />
  )
}

const UiTableAdaptiveCard = (props) => {
  const [last, setLast] = useState(null);
  const [statusListOpen, setStatusListOpen] = useState(false);
  const [statusPayListOpen, setStatusPayListOpen] = useState(false);
  const [curListOpen, setCurListOpen] = useState(false);

  if (props.cols) {
    if (props.cols.length > 0) {
      var cols = props.cols.map((item, index) => {
        if (item.statusList) {
          var statusList = item.statusList.map((item2, index2) => {
            return <span key={index2} onClick={() => {
              setStatusListOpen(false);
              item.onStatusChange(index2);
            }}>{item2}</span>;
          });
        }

        if (item.statusPayList) {
          var statusPayList = item.statusPayList.map((item2, index2) => {
            return <span key={index2} onClick={() => {
              setStatusPayListOpen(false);
              item.onStatusPayChange(index2);
            }}>{item2}</span>;
          });
        }

        if (item.curList) {
          var curList = item.curList.map((item2, index2) => {
            return <span key={index2} onClick={() => {
              setCurListOpen(false);
              item.onCurChange(item2.value);
            }}>{item2.text}</span>;
          });
        }


        return (
          <td
            className={
              "table-card-col" +
              (item.color === "blue" ? " blue" : "") +
              (item.color === "red" ? " red" : "") +
              (item.strong ? " strong" : "")
            }
            key={index}
            onClick={item.onClick ? item.onClick : null}
          >
            <div
              className="table-card-col-inner"
              onClick={() => {
                if (item.sort) {
                  props.onFilter(last == index ? `-${index}` : index);
                  setLast(last == index ? null : index);
                }
              }}
            >
              {item.image ? (
                <img
                  src={/*Env.PUBLIC_URL + */ item.image}
                  style={{ width: "100px", height: "100px", borderRadius: "4px" }}
                />
              ) : null}

              {item.input ? (
                <UiInputCard input={item.input} onBlurInput={(val)=>{
                  item.onBlurInput(val)
                }} />
                
              ) : null}

{item.inputSort ? (
                <div className="table-card-sorting">
                  <button
                    className={"table-card-sorting-button"}
                    onClick={() => item.onBlurInput(0)}
                  >
                    <SvgArrowDown className="table-card-sorting-button-icon up" />
                  </button> 
                   <p>{`${item.inputSort}`}</p>

                  <button
                    className={"table-card-sorting-button"}
                    onClick={() => item.onBlurInput(1)}
                  >
                    <SvgArrowDown className="table-card-sorting-button-icon" />
                  </button>
                </div>
              ) : null}


              {item.button ? (
                <button
                  className={`table-card-button`}
                  onClick={item.openClick ? item.openClick : null}
                >
                  {item.button === "edit" ? (
                    item.editText ? (
                      <span>{item.editText}</span>
                    ) : (
                      <SvgEdit className={`table-card-button-icon ${item.buttonColor}`} />
                    )
                  ) : null}
                  {item.button === "open" ? (
                    <SvgOpen className={`table-card-button-icon ${item.buttonColor}`} />
                  ) : null}
                  {item.button === "delete" ? (
                    <SvgDelete className={`table-card-button-icon ${item.buttonColor}`}/>
                  ) : null}
                  {item.button === "eye" ? (
                    <SvgEye className={`table-card-button-icon ${item.buttonColor}`} />
                  ) : null}
                  {item.button === "done" ? (
                    <SvgCheck className={`table-card-button-icon ${item.buttonColor}`} />
                  ) : null}
                   {item.button === "eye-open" ? (
                    <SvgEyeOpen className={`table-card-button-icon ${item.buttonColor}`} />
                  ) : null}
                </button>
              ) : null}
           
              {props.titles ? (
                <p
                  className={"table-card-title"}
                  onClick={props.textLink ? item.linkClick : null}
                >
                  {props.titles[index].title}
                </p>
              ) : null}
              {item.sort ? <SvgAngle className="table-card-sort" /> : null}
            </div>
            {item.icon ? (
              item.payStatus === "done" ? (
                <SvgCheck />
              ) : item.payStatus === "wait" ? (
                <SvgWait />
              ) : item.icon === "wallet" ? (
                <SvgWallet />
              ) : item.icon === "delivery" ? (
                <SvgDelivery style={{ position: "relative", bottom: -8, marginRight: 12 }} />
              ) : null
            ) : null}
            {item.text ? (
              <span
                className={item.textLink ? "link" : ""}
                onClick={item.linkClick}
                style={item.button && { display : 'none' }}
              >
                {item.text === "" ? "-" : item.text}
              </span>
            ) : null}
            {item.statusInfo ? (
              <div className="status-dropdown">
                <button className="status-button" style={{ maxWidth: 320 }} onClick={() => setStatusListOpen(!statusListOpen)}>
                  <span
                    className={
                      (item.statusColor === "blue" ? " blue" : "") +
                      (item.statusColor === "gray" ? " gray" : "") +
                      (item.statusColor === "green" ? " green" : "") +
                      (item.statusColor === "orange" ? " orange" : "") +
                      (item.statusColor === "red" ? " red" : "") +
                      (item.statusColor === "cyan" ? " cyan" : "") +
                      (item.statusColor === "violet" ? " violet" : "") +
                      (item.statusColor === "sky" ? " sky" : "")
                    }
                    style={{ whiteSpace: "normal" }}
                  >
                    {item.statusInfo}
                  </span>
                </button>
                {statusListOpen ? (
                  <div className="status-dropdown-list">{statusList}</div>
                ) : null}
              </div>
            ) : null}

            {item.curInfo ? (
              <div className="status-dropdown">
                <button className="status-button" onClick={() => setCurListOpen(!curListOpen)}>
                  <span
                    className={
                      (item.statusColor === "blue" ? " blue" : "") +
                      (item.statusColor === "gray" ? " gray" : "") +
                      (item.statusColor === "green" ? " green" : "") +
                      (item.statusColor === "orange" ? " orange" : "") +
                      (item.statusColor === "red" ? " red" : "") +
                      (item.statusColor === "violet" ? " violet" : "") +
                      
                      (item.statusColor === "cyan" ? " cyan" : "") +
                      (item.statusColor === "sky" ? " sky" : "")
                    }
                  >
                    {item.curInfo}
                  </span>
                </button>
                {curListOpen ? (
                  <div className="status-dropdown-list">{curList}</div>
                ) : null}
              </div>
            ) : null}


            {item.statusPayList ? (
              <div className="status-dropdown">
                {item.payStatus === "done" ? (
                  <SvgCheck onClick={() => setStatusPayListOpen(!statusPayListOpen)} />
                ) : item.payStatus === "wait" ? (
                  <SvgWait onClick={() => setStatusPayListOpen(!statusPayListOpen)} />
                ) : null}
                {statusPayListOpen ? (
                  <div className="status-dropdown-list">{statusPayList}</div>
                ) : null}
              </div>
            ) : null}
          </td>
        );
      });
    }
  }

  return (
    <tr
      className={
        "table-card" +
        (!props.adaptive ? " static" : "") +
        (props.isTitle ? " title" : "") +
        (props.grid ? " grid-" + props.grid : "") +
        (props.colored ? " colored" : "") +
        (props.color === "blue" ? " blue" : props.color === "green" ? " green" : props.color === "yellow" ? " yellow" : props.color === "white" ? " white" : props.color === "violet" ? " violet" : "")
      }
    >
      {props.cols ? cols : "-"}
    </tr>
  );
};

export default UiTableAdaptiveCard;
