import React, {useEffect, useState} from "react";
import { connect } from "react-redux";

import { useNavigate } from "react-router-dom";
import EnvConstant from "../../constants/EnvConstant";
import { changeCityState as changeCityStateAction } from "../../redux/modules/login";

import { retrieveData } from "../../services/Storage";
import AppConstant from "../../constants/AppConstant";


import "./UiLogoTitle.scss";

function UiLogoTitle(props, { onClick, size }) {
  const navigate = useNavigate();
  
  const [cityName, setCityName] = useState(AppConstant.APP_CITY[0]);

  useEffect(() => {
    let city = retrieveData("CITY_ID");
    let cityName =  AppConstant.APP_CITY[0];
    AppConstant.APP_CITY.map((item,index) => {
      if(index == city) setCityName(item)
    }) 

  }, [ retrieveData("CITY_ID") ])

  return (
    <h2
      className={size}
      onClick={()=> props.changeCityState(true)}
    >
      {EnvConstant.APP_NAME} CRM
      <br />
      <span> {cityName}</span>
    </h2>
  );
}
 
export default connect(({ login }) => ({ login }), {
  changeCityState: changeCityStateAction ,
})(UiLogoTitle);
