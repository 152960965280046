import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
 
import { ReactComponent as SvgClose } from "../../assets/alert/close.svg"; 

import UiBtnColor from "../button/UiBtnColor";
import UiModal from "./UiModal";

import "./UiModal.scss";
import "./UiModalSelect.scss";

import { retrieveData, storeData } from "../../services/Storage";
import AppConstant from "../../constants/AppConstant";

import { changeCityState as changeCityStateAction } from "../../redux/modules/login";


const UiModalSelect = (props) => {
  const [city, setCity] = useState(0);
  const [cityName, setCityName] = useState(0);

  const changeCity = (val, name) => {
    let n_val = val;
    setCity(n_val);
    storeData("CITY_ID", val);
    setCityName(name); 
  };

  useEffect(() => {
    let id = retrieveData("CITY_ID");
    if(id){
      setCity(id);
    } else {
      storeData("CITY_ID", 0);
      setCity(0);
    }
  }, [props.modalOpen])

  var cities = AppConstant.APP_CITY.map((item, index) => {
    return (
      <button
        key={index}
        className={"alert-city-item" + (index === city ? " active" : "")}
        onClick={() => changeCity(index, item)}
      >
        <span>{item}</span>
      </button>
    );
  });

  return (
    <UiModal  name="modal" show={props.login.cityShowed}>
      <div className="alert">
        <button className="modal-close-btn" onClick={() => props.changeCityState(false) }>
          <SvgClose className="modal-close-btn-icon" />
        </button>
        <div className="alert-body">
          <h3>Выберите Ваш город</h3>
          <div className="alert-city-list">{cities}</div>
          <div className="alert-button">
            <UiBtnColor
              color="orange-light"
              small
              text="Готово"
              onClick={() => { 
                props.changeCityState(false);
                window.location.reload();
              }}
            />
          </div>
        </div>
      </div>
    </UiModal>
  );
};

export default connect(({ login }) => ({ login }), { 
  changeCityState: changeCityStateAction ,
})(UiModalSelect);
