import React, {useState} from "react";
import { useLocation } from "react-router-dom";
import configureStore from "./redux/store";
import { Provider } from "react-redux";

import UiAlert from "./components/modals/UiAlert";
import UiAnimatedAppLoader from "./components/main/UiAnimatedAppLoader";
import UiDialog from "./components/modals/UiDialog";
import UiHeader from "./components/header/UiHeader";
import UiLoader from "./components/modals/UiLoader";
import UiMenu from "./components/menu/UiMenu";

import UiModalSelect from "./components/modals/UiModalSelect";

import AppNavigator from "./navigation/AppNavigator";

import "./styles/app.scss";
import { retrieveData, storeData } from "./services/Storage";
import AppConstant from "./constants/AppConstant";

function App() {
  const store = configureStore();

  let location = useLocation().pathname;
  let uiVisible = !location.includes("/login");

  const [menuActive, setMenuActive] = React.useState(false);
  const [citySelectActive, setCitySelectActive] = useState(false);

  React.useEffect(() => {
    setMenuActive(false); 

    let cityId = retrieveData("CITY_ID") ? retrieveData("CITY_ID") : 0;
     
    storeData("CITY_ID", cityId);

  }, [location]);

  return (
    <Provider store={store}>
      <UiAnimatedAppLoader state={menuActive}/>
      <div className="app" id="app">
        {uiVisible ? (
          <UiHeader
            menuClick={() => {
              setMenuActive(!menuActive);
            }}
            cityClick={() => {
              //setCitySelectActive(!citySelectActive);
            }}
          />
        ) : null}
        <div
          className={
            "app-container ui-grid" + (!uiVisible ? " full-container" : "")
          }
        >
          {uiVisible && <UiMenu menuActive={menuActive} /> }
          
          <div
            className={
              "app-content" +
              (location.includes("/dashboard") ? " no-padding" : "")
            }
          >
            <AppNavigator />
          </div>
        </div>
      </div>

      {/* modals */}
      <UiAlert />
      <UiLoader />
      <UiModalSelect
          modalOpen={citySelectActive}
          modalClose={() => setCitySelectActive(false)}
          onChange={() => {}}
        />

    </Provider>
  );
}

export default App;
