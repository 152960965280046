import Env from "../../services/Env";
import { saveUser, loadUser, logout } from "../../services/Auth";
import {
  regUser,
  checkLogin,
  confirmUser,
  getUser,
  getUserNotification,
  getUserRating,
  readUserNotification,
  getUsers,
  addNotification,
  filter,
  getTable,
  editTable,
  insertTable,
  editUser,
} from "../../services/User";

import {
  storeData,
  retrieveData,
  safelyParseJSON,
} from "../../services/Storage";

const moduleName = "login";

const SET_USER = `${moduleName}/SET_USER`;
const SET_USERS = `${moduleName}/SET_USERS`;
const SET_WORKERS = `${moduleName}/SET_WORKERS`;
const SET_MANAGERS = `${moduleName}/SET_MANAGERS`;
const SET_USER_NOTIFICATIONS = `${moduleName}/SET_USER_NOTIFICATIONS`;
const SET_USER_RATING = `${moduleName}/SET_USER_RATING`;
const SET_TABLES_LIST = `${moduleName}/SET_TABLES_LIST`;

const SET_ERROR = `${moduleName}/SET_ERROR`;
const SET_ERROR_TYPE = `${moduleName}/SET_ERROR_TYPE`;

const SET_USER_INFO = `${moduleName}/SET_USER_INFO`;

const SET_CONFIRM_SHOW = `${moduleName}/SET_CONFIRM_SHOW`;

const SET_CONFIRM_FUNCTION = `${moduleName}/SET_CONFIRM_FUNCTION`;
const SET_ALERT_SHOW = `${moduleName}/SET_ALERT_SHOW`;
const SET_CITY_SHOW = `${moduleName}/SET_CITY_SHOW`;

const SET_ALERT_TITLE = `${moduleName}/SET_ALERT_TITLE`;
const SET_ALERT_TEXT = `${moduleName}/SET_ALERT_TEXT`;
const SET_ALERT_BTN = `${moduleName}/SET_ALERT_BTN`;

const SET_API_REQUEST = `${moduleName}/SET_API_REQUEST`;
const REGISTER = `${moduleName}/REGISTER`;

const defaultState = {
  user: null,
  userNotifications: [],
  userRating: [],
  usersList: [],
  usersWorker: [],
  usersManager: [],

  tablesList: [],

  userInfo: null,
  error: false,

  confirmShowed: false,
  confirmFunc: null,
  alertShowed: false,
  cityShowed: false,
  alertTitle: "Внимание",
  alertText: "",
  alertButton: "Ok",
  errorType: 3,
  isFetching: false,
};

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case SET_USER:
      return { ...state, user: payload };
    case SET_USERS:
      return { ...state, usersList: payload };
    case SET_WORKERS:
      return { ...state, usersWorker: payload };
    case SET_MANAGERS:
      return { ...state, usersManager: payload };

    case SET_USER_NOTIFICATIONS:
      return { ...state, userNotifications: payload };
    case SET_USER_RATING:
      return { ...state, userRating: payload };
    case SET_TABLES_LIST:
      return { ...state, tablesList: payload };

    case SET_ERROR:
      return { ...state, error: payload };
    case SET_ERROR_TYPE:
      return { ...state, errorType: payload };

    case SET_USER_INFO:
      return { ...state, userInfo: payload };

    case SET_CONFIRM_SHOW:
      return { ...state, confirmShowed: payload };
    case SET_CONFIRM_FUNCTION:
      return { ...state, confirmFunc: payload };

    case SET_CITY_SHOW:
      return { ...state, cityShowed: payload };

    case SET_ALERT_SHOW:
      return { ...state, alertShowed: payload };
    case SET_ALERT_TITLE:
      return { ...state, alertTitle: payload };
    case SET_ALERT_TEXT:
      return { ...state, alertText: payload };
    case SET_ALERT_BTN:
      return { ...state, alertButton: payload };

    case SET_API_REQUEST:
      console.log("fetching API request", payload);
      return { ...state, isFetching: payload };
    case REGISTER:
      return { ...state, isFetching: payload };

    default:
      return state;
  }
};

export const changeConfirmState =
  (value = true, text = "", func = null) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SET_CONFIRM_SHOW,
        payload: value,
      });
      dispatch({
        type: SET_CONFIRM_FUNCTION,
        payload: func,
      });

      dispatch({
        type: SET_ALERT_TITLE,
        payload: "Внимание",
      });
      dispatch({
        type: SET_ALERT_TEXT,
        payload: text,
      });
    } catch (error) {
      console.error(error);
    }
  };

export const changeAlertState =
  (value = true, text = "", title = "Внимание", button = "Ok") =>
  async (dispatch) => {
    try {
      dispatch({
        type: SET_ALERT_SHOW,
        payload: value,
      });
      dispatch({
        type: SET_ALERT_TITLE,
        payload: title,
      });
      dispatch({
        type: SET_ALERT_TEXT,
        payload: text,
      });
      dispatch({
        type: SET_ALERT_BTN,
        payload: button,
      });
    } catch (error) {
      console.error(error);
    }
  };

  
export const changeCityState =
(value = true ) =>
async (dispatch) => {
  try {
    dispatch({
      type: SET_CITY_SHOW,
      payload: value,
    });
     
  } catch (error) {
    console.error(error);
  }
};


export const setUser = (value) => async (dispatch) => {
  try {
    dispatch({
      type: SET_USER,
      payload: value,
    });
    saveUser(value);
  } catch (error) {
    console.error(error);
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    logout();
    return dispatch({
      type: SET_USER,
      payload: null,
    });
  } catch (error) {
    console.error(error);
  }
};

export const getStoredUser = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_USER,
      payload: loadUser(),
    });
    let user = loadUser();
    if (user) {
      console.log("user", user);
      dispatch(getUserInfo(user.api_token, user.id));
    }

    return loadUser();
  } catch (error) {
    console.error(error);
  }
};

export const getTopUsers = () => async (dispatch, getState) => {
  try {
    if (getState().login.user) {
      return getUserRating(getState().login.user.api_token).then((res) => {
        console.log(res);

        if (res.response) {
          let _user = res.response;
          dispatch({
            type: SET_USER_RATING,
            payload: _user,
          });
        }

        return res.response;
      });
    }
  } catch (error) {
    console.error(error);
  }
};

export const setErrorType = (value) => async (dispatch) => {
  try {
    dispatch({
      type: SET_ERROR_TYPE,
      payload: value,
    });
  } catch (error) {
    console.error(error);
  }
};

export const loginUser = (email, code) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_API_REQUEST,
      payload: true,
    });
    dispatch({
      type: SET_ERROR_TYPE,
      payload: 1,
    });

    checkLogin(email, code).then((res) => {
      if (
        res.status == 200 &&
        res.response &&
        (res.response.type == 0 ||
          res.response.type == 1 ||
          res.response.type == 2 ||
          res.response.is_worker == 1)
      ) {
        let _user = res.response;

        saveUser(_user);
        dispatch({
          type: SET_USER,
          payload: _user,
        });
        dispatch({
          type: SET_ERROR,
          payload: false,
        });
        dispatch({
          type: SET_ERROR_TYPE,
          payload: 0,
        });
      } else {
        dispatch({
          type: SET_ERROR,
          payload: true,
        });
        dispatch({
          type: SET_ERROR_TYPE,
          payload: 2,
        });
      }
      dispatch({
        type: SET_API_REQUEST,
        payload: false,
      });
    });
  } catch (error) {
    console.error(error);
  }
};

export const registerUser = (_email) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_API_REQUEST,
      payload: true,
    });

    return regUser(_email).then((res) => {
      dispatch({
        type: SET_API_REQUEST,
        payload: false,
      });
      return res.response;
    });
  } catch (error) {
    console.error(error);
  }
};

export const getUserInfo = (_api, _id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_API_REQUEST,
      payload: true,
    });

    return getUser(_api, _id, false).then((res) => {
      if (res.response) {
        let _user = res.response;

        saveUser(_user);
        dispatch({
          type: SET_USER,
          payload: _user,
        });
      }
      dispatch({
        type: SET_API_REQUEST,
        payload: false,
      });
      return res.response;
    });
  } catch (error) {
    console.error(error);
  }
};

export const showUserInfo = (_api, _id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_API_REQUEST,
      payload: true,
    });

    return getUser(_api, _id, true).then((res) => {
      if (res.response) {
        let _user = res.response;

        dispatch({
          type: SET_USER_INFO,
          payload: _user,
        });
      }
      dispatch({
        type: SET_API_REQUEST,
        payload: false,
      });
      return res.response;
    });
  } catch (error) {
    console.error(error);
  }
};

export const filterUser = (_key, _filter) => async (dispatch, getState) => {
  if (getState().login.user.api_token) {
    try {
      dispatch({
        type: SET_API_REQUEST,
        payload: true,
      });

      return filter(getState().login.user.api_token, _key, _filter).then(
        (res) => {
          console.log(res);
          if (res.status == 200) {
            let _data = res.response;
          }
          dispatch({
            type: SET_API_REQUEST,
            payload: false,
          });
          return res.response;
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
};

export const getWorkers = () => async (dispatch, getState) => {
  if (getState().login.user.api_token) {
    try {
      dispatch({
        type: SET_API_REQUEST,
        payload: true,
      });

      return filter(getState().login.user.api_token, "is_worker", 1).then(
        (res) => {
          console.log(res);
          if (res.status == 200) {
            let _data = res.response;
            _data.push({ id: 0, name: "-" });
            dispatch({
              type: SET_WORKERS,
              payload: _data,
            });
          }
          dispatch({
            type: SET_API_REQUEST,
            payload: false,
          });
          return res.response;
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
};

export const getManager = () => async (dispatch, getState) => {
  if (getState().login.user.api_token) {
    try {
      dispatch({
        type: SET_API_REQUEST,
        payload: true,
      });

      return filter(getState().login.user.api_token, "type", 2).then((res) => {
        if (res.status == 200) {
          return filter(getState().login.user.api_token, "type", 1).then(
            (res2) => {
              if (res2.status == 200) {
                let _data = res.response;
                _data = _data.concat(res2.response);
                dispatch({
                  type: SET_MANAGERS,
                  payload: _data,
                });
              }
              return res2.response;
            }
          );
        }
        dispatch({
          type: SET_API_REQUEST,
          payload: false,
        });
        return res.response;
      });
    } catch (error) {
      console.error(error);
    }
  }
};

export const editUserData = (_data) => async (dispatch, getState) => {
  if (getState().login.user.api_token) {
    try {
      dispatch({
        type: SET_API_REQUEST,
        payload: true,
      });
      _data.api_token = getState().login.user.api_token;

      return editUser(_data).then(() => {
        dispatch({
          type: SET_API_REQUEST,
          payload: false,
        });
      });
    } catch (error) {
      console.error(error);
    }
  }
};

export const addNotify =
  (_type, _value, _text) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SET_API_REQUEST,
        payload: true,
      });

      return addNotification(
        getState().login.user.api_token,
        _type,
        _value,
        _text
      ).then((res) => {
        if (res.response) {
        }
        dispatch({
          type: SET_API_REQUEST,
          payload: false,
        });
        return res.response;
      });
    } catch (error) {
      console.error(error);
    }
  };

export const getTableList = (_name) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_API_REQUEST,
      payload: true,
    });

    return getTable(getState().login.user.api_token, _name)
      .then((res) => {
        if (res.response) {
          let _data = res.response;

          dispatch({
            type: SET_TABLES_LIST,
            payload: _data,
          });
        }
        dispatch({
          type: SET_API_REQUEST,
          payload: false,
        });
        return res.response;
      })
      .catch((err) => {
        dispatch({
          type: SET_API_REQUEST,
          payload: false,
        });
        alert(JSON.stringify(err));
      });
  } catch (error) {
    console.error(error);
  }
};

export const editTableValue =
  (_table, _item_title, _item, _value_title, _value) =>
  async (dispatch, getState) => {
    console.log(_table, _item_title, _item, _value_title, _value);
    try {
      dispatch({
        type: SET_API_REQUEST,
        payload: true,
      });

      return editTable(
        getState().login.user.api_token,
        _table,
        _value_title,
        _value,
        _item_title,
        _item
      ).then((res) => {
        if (res.response) {
        }
        dispatch({
          type: SET_API_REQUEST,
          payload: false,
        });
        return res.response;
      });
    } catch (error) {
      console.error(error);
    }
  };

export const insertTableValue = (_data) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_API_REQUEST,
      payload: true,
    });

    _data.api_token = getState().login.user.api_token;

    return insertTable(_data).then((res) => {
      if (res.response) {
      }
      dispatch({
        type: SET_API_REQUEST,
        payload: false,
      });
      return res.response;
    });
  } catch (error) {
    console.error(error);
  }
};
