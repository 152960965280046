import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { addFile, retrieveData } from "../../services/Storage";
import { useNavigate, useParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { ReactComponent as SvgClose } from "../../assets/images/ui/close.svg";

import { TagsInput } from "react-tag-input-component";

import UiInputText from "../../components/inputs/UiInputText";
import UiModalImageCrop from "../../components/modals/UiModalImageCrop";
import UiSelect from "../../components/select/UiSelect";
import UiDropDown from "../../components/inputs/UiDropDown";
import UiTextArea from "../../components/inputs/UiTextArea";
import UiCardAddPhoto from "../../components/cards/UiCardAddPhoto";
import UiButtonColor from "../../components/button/UiButtonColor";
import UiTableAdaptive from "../../components/table/UiTableAdaptive";

import UiModalEditComposition from "../../components/modals/UiModalEditComposition";

import {
  addSpecialComposition as addSpecialCompositionAction,
  destroySpecialComposition as destroySpecialCompositionAction,
  editSpecialComposition as editSpecialCompositionAction,
} from "../../redux/modules/order";
import {
  getCategoryList as getCategoryListAction,
  getSubCategoryList as getSubCategoryListAction,
} from "../../redux/modules/category";
import {
  getCompositionByStorage as getCompositionByStorageAction,
  addStorage as addStorageAction,
  update as updateAction,
} from "../../redux/modules/storage";

import Env from "../../services/Env";
import { addFileBase64, convertImages } from "../../services/Storage";
import { formatDateYMD } from "../../utilities/date";
import getCroppedImg from "../../services/CropImage";
import StorageConstant from "../../constants/StorageConstant";
import OrderConstant from "../../constants/OrderConstant";
import AppConstant from "../../constants/AppConstant";

import "./ProductsStyle.scss";

import {
  toArrayListKey,
  toArrayList,
  toArrayImagesListKey,
  fromArrayListKey,
  inArrayValueByKey,
  inArray,
  inArrayValue,
  unCheckInArray,
} from "../../utilities/array";

function ProductsAddPage(props) {
  const navigate = useNavigate();
  const [selectedCity, setSelectedCity] = useState(0);
  const [order, setOrder] = useState({
    isEdit: false,

    title: "",
    text: "",
    type: 0,
    category: "2",
    sub_category: 0,
    price: 0,
    tags: "",
    sale: 0,
    city: AppConstant.APP_CITY.map((i, ind) => {
      return ind;
    }).join(","),
    sale_count: 0,
    start_date: null,
    end_date: null,
    additional: [],
    images: [],
  });
  const [state, setState] = useState({
    isEditOpen: false,
    isLoad: false,
    isDialogCrop: false,
    isDialogCrop2: false,
    isDialogCrop3: false,
    selectedCustomItem: null,
    customId: null,
    customIndex: null,
    addPhoto: null,
    addTitle: "S",
    addText: "",
    addPhotos: [],
    savedImages: [],
    removeArray: [],
    imagesList: [],
  });
  const [additional, setAdditional] = useState([]);

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "white",
    borderRadius: "10px",
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: grid,
    width: 250,
  });

  const clientsTitles = [
    { sort: false, title: "" },
    { sort: false, title: "" },
    { sort: false, title: "Изображение" },
    { sort: true, title: "Название" },
    { sort: false, title: "Цена" },
  ];

  useEffect(() => {
    props.getCategoryList();
    props.getSubCategoryList();
    let cityId = retrieveData("CITY_ID");
    setSelectedCity(cityId);

    let editData = retrieveData("edit");
    if (editData) {
      let imgs = toArrayImagesListKey(editData.images);
      setState({ ...state, imagesList: imgs });
      editData.images = imgs;
      editData.isEdit = true;
      setOrder(editData);
      getAdditional(editData.id);
    } else {
      let _order = order;
      _order.category = "";
      setOrder(_order);
    }
  }, []);

  function getAdditional(_id) {
    props.getCompositionByStorage(_id).then((res) => {
      if (res.response) setAdditional(res.response);
    });
  }

  function uploadList(file) {
    addFile(file, 2).then((res) => {
      if (res.success) {
        setState({ ...state, addPhoto: res.response });
      }
    });
  }

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      state.imagesList,
      result.source.index,
      result.destination.index
    );

    setState({
      ...state,
      imagesList: items,
    });
  }

  function convertArrayToAdditional(_arr) {
    let arr = [];
    _arr
      .sort((a, b) => a.price - b.price)
      .map((item, itemIndex) => {
        arr.push([
          {
            button: "delete",
            openClick: () => {
              if (item.id)
                props.destroySpecialComposition(item.id).then(() => {
                  getAdditional(order.id);
                });
              else {
                setState({ ...state, isLoad: true });

                let rem = additional;
                rem.splice(additional.length - 1, 1);
                setAdditional(rem);
                setState({ ...state, isLoad: false });
              }
            },
          },
          {
            button: "edit",
            openClick: () => {
              setState({
                ...state,
                isEditOpen: true,
                selectedCustomItem: item,
                customId: item.id ? item.id : null,
                customIndex: itemIndex,
              });
            },
          },
          {
            image: item.custom_image
              ? convertImages(item.custom_image, Env.PUBLIC_URL)[0]
              : null,
          },
          { text: item.custom_title },
          { text: `${item.custom_price} ₽` },
        ]);
      });
    return arr;
  }

  /**
   * Resolve adding alternative
   * @param {Array} arr
   * @returns
   */
  async function _addAlternative(_arr, _id) {
    console.log(_arr, _id);
    return await Promise.all(
      _arr.map(async (element) => {
        if (!element.id)
          return props.addSpecialComposition({
            order_id: null,
            storage_id: _id,
            custom_image: element.custom_image,
            custom_title: element.custom_title,
            custom_text: element.custom_text,
            custom_count: element.custom_count,
            custom_price: element.custom_price,
          });
      })
    );
  }

  /**
   *
   * @param {Array} _arr
   * @returns
   */
  function _convertImgs(_arr) {
    let arr = [];
    _arr.map((item, index) => {
      arr.push({ id: `item-${index}`, content: item });
    });
    return arr;
  }

  /**
   *
   * @param {string} key
   * @param {any} val
   */
  function updateOrderObj(key, val) {
    let _obj = order;
    _obj[key] = val;
    setOrder({ ..._obj });
  }

  function _onSaveImage(_croppedAreaPixels) {
    setState({ ...state, isDialogCrop: false });
    getCroppedImg(state.savedImages[0], _croppedAreaPixels, 0).then((res) => {
      setState({ ...state, selectedFileName: res });
      let arr = state.savedImages;
      arr.splice(0, 1);
      setState({ ...state, savedImages: arr });
      upload(res);
    });
  }

  function _onSaveImage2(_croppedAreaPixels) {
    setState({ ...state, isDialogCrop2: false });
    getCroppedImg(state.savedImages[0], _croppedAreaPixels, 0).then((res) => {
      setState({ ...state, selectedFileName: res });
      upload2(res);
    });
  }

  function _onSaveImage3(_croppedAreaPixels) {
    setState({ ...state, isDialogCrop3: false });
    getCroppedImg(state.savedImages[0], _croppedAreaPixels, 0).then((res) => {
      setState({ ...state, selectedFileName: res });
      upload3(res);
    });
  }

  function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  function upload(file) {
    setState({
      ...state,
      imageLoad: true,
    });
    addFileBase64(file, 0).then((res) => {
      setState({
        ...state,
        imageLoad: false,
      });
      if (res.success) {
        let arr = state.imagesList;
        arr.push({ id: (arr.length + 1).toString(), content: res.response });
        setState({
          ...state,
          imagesList: arr,
        });
        setState({
          ...state,
          isDialogCrop: state.savedImages.length > 0,
        });
      } else {
        console.log(res);
      }
    });
  }

  function upload2(file) {
    setState({
      ...state,
      imageLoad: true,
    });
    addFileBase64(file, 0).then((res) => {
      setState({
        ...state,
        imageLoad: false,
        isDialogCrop2: false,
      });
      if (res.success) {
        let arr = state.addPhotos;
        arr.push(res.response);
        setState({
          ...state,
          addPhoto: res.response,
          savedImages: [],
          addPhotos: arr,
          isDialogCrop2: false,
        });
      } else {
        console.log(res);
      }
    });
  }

  function upload3(file) {
    setState({
      ...state,
      imageLoad: true,
    });
    addFileBase64(file, 0).then((res) => {
      setState({
        ...state,
        imageLoad: false,
        isDialogCrop3: false,
      });
      if (res.success) {
        setState({
          ...state,
          isDialogCrop3: false,
        });

        //save
        let newItem = state.selectedCustomItem;
        console.log(newItem);
        let nArr = convertImages(newItem.custom_image);
        nArr.push(res.response);
        newItem.custom_image = nArr.join(",");
        if (state.customId) {
          props.editSpecialComposition(newItem).then(() => {
            getAdditional(order.id);
          });
        } else {
          let _arr = additional;
          additional[state.customIndex] = newItem;
          setState({ ...state, additional: _arr, isLoad: false });
        }
      } else {
        console.log(res);
      }
    });
  }

  function _remImg(index, id) {
    let arr = state.imagesList;
    let _removeArray = state.removeArray;
    _removeArray.push(id);
    arr.splice(index, 1);
    setState({
      ...state,
      removeArray: _removeArray,
      imagesList: arr,
    });
    updateOrderObj("imgs", arr);
  }

  function _remCustomImg(index, id) {
    let arr = state.addPhotos;

    arr.splice(index, 1);
    setState({
      ...state,
      addPhotos: arr,
    });
  }

  let tags = (order.tags ? order.tags.split(",") : []).map((item, index) => {
    return (
      <span key={index} className={"product-add-info-tags"}>
        {item}
      </span>
    );
  });

  let cat_tags = (order.category ? order.category.split(",") : []).map(
    (item, index) => {
      return (
        <span key={index} className={"product-add-info-tags"}>
          <b>
            {props.category
              ? props.category.list.length > 0
                ? inArrayValueByKey(props.category.list, "id", item)?.title
                : 0
              : 0}
          </b>

          <button
            style={{ color: "red" }}
            onClick={() => {
              let arr = order.category ? order.category.split(",") : [];
              arr.splice(index, 1);
              updateOrderObj("category", arr.join(","));
            }}
          >
            {" "}
            x
          </button>
        </span>
      );
    }
  );

  let citiesList = (order.city ? order.city.split(",") : []).map(
    (item, index) => {
      return (
        <span key={index} className={"product-add-info-tags"}>
          <b> {AppConstant.APP_CITY[item]}</b>

          <button
            onClick={() => {
              let arr = order.city ? order.city.split(",") : [];
              arr.splice(index, 1);
              updateOrderObj("city", arr.join(","));
            }}
          >
            {" "}
            X
          </button>
        </span>
      );
    }
  );

  return (
    <div id="page" className="product-add-page">
      <div className="product-add-wrap">
        <div className="product-add-about add">
          <div className="product-add-about-title">
            <div className="product-add-about-title-wrap">
              <h3>Добавить товар</h3>
            </div>
          </div>
          <div className="product-add-notes">
            <div className="product-add-notes-list-wrap">
              <div className="product-add-info">
                <div className="product-add-info-form">
                  <UiInputText
                    label="Название товара:"
                    placeholder="Название товара"
                    value={order.title}
                    onChange={(val) => {
                      updateOrderObj("title", val);
                    }}
                  />
                </div>

                <div className="product-add-info-form">
                  <h3>Города для букета:</h3>
                  <div className="product-add-info-tags-wrap">{citiesList}</div>

                  <UiDropDown
                    label="Добавить Город:"
                    items={toArrayList(AppConstant.APP_CITY)}
                    onChange={(val) => {
                      updateOrderObj(
                        "city",
                        unCheckInArray(order.city.split(","), val).join(",")
                      );
                    }}
                  />
                </div>

                <div className="product-add-info-form">
                  <h3>Выбранная категория:</h3>
                  <div className="product-add-info-tags-wrap">{cat_tags}</div>

                  <UiDropDown
                    label="Выбрать категорию:"
                    items={toArrayListKey(
                      props.category ? props.category.list : [],
                      "title",
                      true
                    )}
                    onChange={(val) => {
                      let arr = order.category ? order.category.split(",") : [];
                      if (
                        !(order.category ? order.category : "").includes(val)
                      ) {
                        arr.push(val);
                      } else {
                        let i = 0;
                        arr.map((item, index) => {
                          if (item == val) i = index;
                        });
                        arr.splice(i, 1);
                      }
                      updateOrderObj("category", arr.join(","));
                    }}
                  />
                </div>
                {/*
                <div className="product-add-info-form">
                  <UiDropDown
                    label="Под Категория:"
                    value={
                      props.category
                        ? props.category.subList.length > 0
                          ? inArrayValueByKey(
                              props.category.subList,
                              "id",
                              order?.sub_category
                            )?.id
                          : 0
                        : 0
                    }
                    items={toArrayListKey(
                      props.category ? props.category.subList : [],
                      "title"
                    )}
                    onChange={(val) => updateOrderObj("sub_category", val)}
                  />
                </div>
                    */}
                <div className="product-add-info-form">
                  <UiTextArea
                    label="Краткое описание"
                    value={order.text}
                    onChange={(val) => updateOrderObj("text", val)}
                  />
                </div>

                <div className="product-add-info-form"> 
                  <pre>{JSON.stringify( order.search_tags)}</pre>
                  <TagsInput
                    value={order.search_tags?.split(",")}
                    onChange={(arr) => {
                      updateOrderObj("search_tags", arr.join(","));
                    }} 
                    placeHolder="Тэги для поиска"
                  />
                  <em>Нажмите Enter или запятую для добавления </em>
                </div>

                <div className="product-add-info-form">
                  <div className="product-add-info-tags-wrap">{tags}</div>
                  <UiSelect
                    label="Выбрать Метку"
                    optionList={StorageConstant.TAGS_ARRAY}
                    onChange={(val) => {
                      let valText = inArrayValueByKey(
                        StorageConstant.TAGS_ARRAY,
                        "value",
                        val
                      ).text;
                      let arr = order.tags ? order.tags.split(",") : [];
                      if (!(order.tags ? order.tags : "").includes(valText)) {
                        arr.push(valText);
                      } else {
                        let i = 0;
                        arr.map((item, index) => {
                          if (item == valText) i = index;
                        });
                        arr.splice(i, 1);
                      }
                      updateOrderObj("tags", arr.join(","));
                    }}
                  />
                </div>

                <div className="product-add-info-form">
                  <UiInputText
                    label="Цена"
                    value={order.price}
                    onChange={(val) => updateOrderObj("price", val)}
                  />
                </div>

                <div className="product-add-info-form">
                  <UiInputText
                    label="Старая Цена"
                    value={order.old_price}
                    onChange={(val) => updateOrderObj("old_price", val)}
                  />
                </div>


                {/*
                                <div className="product-add-info-form">
                                    <UiInputText
                                        label="Цена от скольки шт"
                                        value={order.sale_count}
                                        onChange={(val) => updateOrderObj("sale_count", val)}
                                    />
                                </div>
                                <div className="product-add-info-form">
                                    <UiInputText
                                        label="Цена от"
                                        value={order.sale}
                                        onChange={(val) => updateOrderObj("sale", val)}
                                    />
                                </div>
                                */}

                <div className="product-add-info-form">
                  <UiInputText
                    label=" С какого числа доступен букет"
                    type={"date"}
                    value={
                      order.start_date
                        ? formatDateYMD(order.start_date, "-")
                        : null
                    }
                    onChange={(val) => updateOrderObj("start_date", val)}
                  />
                </div>
                <div className="product-add-info-form">
                  <UiInputText
                    label="До какой даты доступен буке"
                    type={"date"}
                    value={
                      order.end_date ? formatDateYMD(order.end_date, "-") : null
                    }
                    onChange={(val) => updateOrderObj("end_date", val)}
                  />
                </div>

                <div className="product-add-info-form">
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                        >
                          {state.imagesList.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  <UiCardAddPhoto
                                    key={index}
                                    image={Env.PUBLIC_URL + item.content}
                                    remove={() => {
                                      _remImg(index, item.content);
                                    }}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>

                <div className="product-add-info-form product-add-info-photo">
                  <UiCardAddPhoto
                    upload={(e) => {
                      if (e.target.files.length > 0) {
                        for (let i = 0; i < e.target.files.length; i++) {
                          let arr = state.savedImages;
                          getBase64(e.target.files[i], (result) => {
                            arr.push(result);
                            setState({
                              ...state,
                              savedImages: arr,
                              base64Image: result,
                              isDialogCrop: true,
                            });
                          });
                        }
                      }
                    }}
                  />
                </div>

                <div className="product-add-info-form product-add-info-photo">
                  <UiButtonColor
                    color="primary"
                    text={order.isEdit ? "Редактировать" : "Создать"}
                    small={true}
                    onClick={() => {
                      if (
                        order.title != "" &&
                        order.text != "" &&
                        state.imagesList.length > 0
                      ) {
                        if (order.isEdit) {
                          let _order = order;
                          _order.additional = additional;
                          _order.remove = state.removeArray;
                          _order.images = fromArrayListKey(
                            state.imagesList,
                            "content"
                          );
                          _addAlternative(additional, _order.id).then(
                            (resolve) => {
                              props.update(_order).then((res) => {
                                if (res.status == 200)
                                  navigate(
                                    `/products/category/${
                                      _order.category.split(",")[0]
                                    }`
                                  );
                              });
                            }
                          );
                        } else {
                          let _order = order;
                          _order.additional = additional;
                          _order.images = fromArrayListKey(
                            state.imagesList,
                            "content"
                          );
                          console.log(_order);

                          props.addStorage(_order).then((res) => {
                            if (res.status == 200) {
                              if (additional.length > 0) {
                                _addAlternative(
                                  additional,
                                  res.response.id
                                ).then((resolve) => {
                                  navigate(
                                    `/products/category/${
                                      _order.category.split(",")[0]
                                    }`
                                  );
                                });
                              } else {
                                navigate(
                                  `/products/category/${
                                    _order.category.split(",")[0]
                                  }`
                                );
                              }
                            }
                          });
                        }
                      } else {
                        alert("Заполните поля название, описание и фотографии");
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="product-add-info-wrap">
          <div className="product-add-info">
            <h3>Варианты товара</h3>
            <div className="product-add-info-form">
              <div className="added-list">
                {!state.isLoad ? (
                  <UiTableAdaptive
                    titleList={clientsTitles}
                    grid={clientsTitles.length}
                    tableList={convertArrayToAdditional(additional)}
                    search={false}
                  />
                ) : null}
              </div>

              <UiInputText
                label="Фотография"
                type="file"
                onChange={(e) => {
                  getBase64(
                    e.target.files[e.target.files.length - 1],
                    (result) => {
                      let arr = state.savedImages;
                      arr.push(result);
                      console.log(arr);
                      setState({
                        ...state,
                        savedImages: arr,
                        base64Image: result,
                        isDialogCrop2: true,
                      });
                    }
                  );
                }}
              />

              <div>
                {state.addPhotos.map((item, index) => {
                  return (
                    <UiCardAddPhoto
                      key={index}
                      image={Env.PUBLIC_URL + item}
                      remove={() => {
                        _remCustomImg(index, item);
                      }}
                    />
                  );
                })}
              </div>

              {/*
                           
                          <UiDropDown
                label="Размер:"
                items={[
                  { value: "S", text: "S" },
                  { value: "M", text: "M" },
                  { value: "L", text: "L" },
                  { value: "X", text: "X" },
                  { value: "XL", text: "XL" },
                ]}
                onChange={(val) => setState({ ...state, addTitle: val })}
              /> 

                            */}

              <UiInputText
                label="Название Размера:"
                onChange={(val) => setState({ ...state, addTitle: val })}
              />

              <UiInputText
                label="Цена"
                type="number"
                onChange={(val) => setState({ ...state, addPrice: val })}
              />

              <UiTextArea
                label="Краткое описание"
                onChange={(val) => setState({ ...state, addText: val })}
              />

              <UiButtonColor
                color="gray"
                text="Добавить размер\вариант"
                small={true}
                onClick={() => {
                  setState({ ...state, isLoad: true });
                  let arr = additional;
                  arr.push({
                    index: arr.length + 1,
                    custom_image: state.addPhotos.join(","),
                    custom_title: state.addTitle,
                    custom_text: state.addText,
                    custom_count: 1,
                    custom_price: state.addPrice,
                  });
                  console.log(arr);
                  setAdditional(arr);
                  setState({ ...state, addPhotos: [], isLoad: false });
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <UiModalImageCrop
        modalOpen={state.isDialogCrop}
        base64Image={state.savedImages[0]}
        onSave={(val) => _onSaveImage(val)}
        cancelClick={() => setState({ ...state, isDialogCrop: false })}
      />

      <UiModalImageCrop
        modalOpen={state.isDialogCrop2}
        base64Image={state.savedImages[0]}
        onSave={(val) => _onSaveImage2(val)}
        cancelClick={() => setState({ ...state, isDialogCrop2: false })}
      />

      <UiModalImageCrop
        modalOpen={state.isDialogCrop3}
        base64Image={state.savedImages[0]}
        onSave={(val) => _onSaveImage3(val)}
        cancelClick={() => setState({ ...state, isDialogCrop3: false })}
      />

      <UiModalEditComposition
        modalOpen={state.isEditOpen}
        item={state.selectedCustomItem}
        onSave={(val) => {
          setState({ ...state, isEditOpen: false });
          console.log(val);
          // val.custom_image = '1335x890-1683577131.jpg,1335x890-1683577140.jpg,1335x890-1683577150.jpg,1335x890-1686430887.jpg,1335x890-1686430953.jpg';
          if (state.customId)
            props.editSpecialComposition(val).then(() => {
              getAdditional(order.id);
            });
          else {
            let _arr = additional;
            additional[state.customIndex] = val;
            setState({ ...state, additional: _arr, isLoad: false });
          }
        }}
        onChangeImage={(arr) => {
          setState({
            ...state,
            savedImages: arr,
            isEditOpen: false,
            isDialogImageEdit: true,
            isDialogCrop3: true,
          });
        }}
        cancelClick={() => setState({ ...state, isEditOpen: false })}
      />
    </div>
  );
}

export default connect(({ category }) => ({ category }), {
  getSubCategoryList: getSubCategoryListAction,
  getCategoryList: getCategoryListAction,
  addStorage: addStorageAction,
  update: updateAction,

  addSpecialComposition: addSpecialCompositionAction,
  destroySpecialComposition: destroySpecialCompositionAction,
  editSpecialComposition: editSpecialCompositionAction,
  getCompositionByStorage: getCompositionByStorageAction,
})(ProductsAddPage);
