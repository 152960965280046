import React from "react";

import "./UiBtnColor.scss";
import UiIcon from "../icon/UiIcon";

const UiBtnColor = (props) => {
  return (
    <button
      className={
        "btn" +
        (props.color ? " btn-" + props.color : "") +
        (props.disabled ? " disabled" : "") +
        (props.fill ? " fill" : "") +
        (props.small ? " small" : "")
      }
      disabled={props.disabled}
      onClick={() => props.onClick()}
    >
      {props.icon && <UiIcon icon={props.icon} fill={props.color} size={13} />}
      <span>{props.text}</span>
    </button>
  );
};
export default UiBtnColor;
