export default {
  APP_DOMAIN: "alice-flower.ru",
  APP_NAME: "getbuquet",
  APP_NAME_EN: "getbuquet_site",
  APP_ADDRESS: "г. Белгород, ул. Улица, д. 111",
  APP_CITY: "Белгород",
  APP_MAIL: "mailto@gmail.com",
  APP_MAIL_LINK: "#",
  APP_PHONE: "7 (4722) 20-56-85",
  APP_PHONE_LINK: "tel:+74722205685",
  APP_WORK_TIME: "9:00 — 21:00",
  APP_INSTAGRAM: "#",
  APP_TELEGRAM: "https://t.me/AliceFlower_bot",
  APP_VK: "#",
  APP_WHATSAPP: "#",

  

  APP_CITY: [
    "Урай", 
  ]
};
