import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { addFile, retrieveData } from "../../services/Storage";
import { useNavigate, useParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { ReactComponent as SvgClose } from "../../assets/images/ui/close.svg";

import UiInputText from "../../components/inputs/UiInputText";
import UiModalImageCrop from "../../components/modals/UiModalImageCrop";
import UiDropDown from "../../components/inputs/UiDropDown";
import UiTextArea from "../../components/inputs/UiTextArea";
import UiCardAddPhoto from "../../components/cards/UiCardAddPhoto";
import UiButtonColor from "../../components/button/UiButtonColor";
import UiTableAdaptive from "../../components/table/UiTableAdaptive";

import { addCategory as addCategoryAction } from "../../redux/modules/category";
import {
  addStorage as addStorageAction,
  update as updateAction,
} from "../../redux/modules/storage";

import "./ProductsStyle.scss";

function ProductsCategoryAddPage(props) {
  const navigate = useNavigate();
  const [selectedCity, setSelectedCity] = useState(0);
  const [order, setOrder] = useState({
    isEdit: false,
    title: "",
    category: 0,
    city: 0,
    is_menu: 1,
    is_site_menu: 0,
    description: "",
  });
  const [state, setState] = useState({
    isLoad: false,
    isDialogCrop: false,
    savedImages: [],
    removeArray: [],
    imagesList: [],
  });
  const [additional, setAdditional] = useState([]);

  useEffect(() => {
    let cityId = retrieveData("CITY_ID");
 
    let _order = order;
    _order.city = cityId;
    setOrder(_order);
  }, []);

  /**
   *
   * @param {string} key
   * @param {any} val
   */
  function updateOrderObj(key, val) {
    let _obj = order;
    _obj[key] = val;
    setOrder({ ..._obj });
  }

  return (
    <div id="page" className="product-add-page">
      <div className="product-add-wrap">
        <div className="product-add-about add">
          <div className="product-add-about-title">
            <div className="product-add-about-title-wrap">
              <h3>Добавить категорию</h3>
            </div>
          </div>
          <div className="product-add-notes">
            <div className="product-add-notes-list-wrap">
              <div className="product-add-info">
                <div className="product-add-info-form">
                  <UiInputText
                    label="Название:"
                    placeholder="Название"
                    value={order.title}
                    onChange={(val) => {
                      updateOrderObj("title", val);
                    }}
                  />
                </div>
                <div className="product-add-info-form">
                  <UiDropDown
                    label="Тип:"
                    items={[
                      { value: 0, text: "Категория" },
                      { value: 1, text: "Под категория" },
                    ]}
                    onChange={(val) => updateOrderObj("category", val)}
                  />
                </div>
                <div className="product-add-info-form">
                  <UiDropDown
                    label="Показать в меню:"
                    items={[
                      { value: 1, text: "Скрыть" },
                      { value: 0, text: "Показать" },
                    ]}
                    onChange={(val) => updateOrderObj("is_menu", val)}
                  />
                </div>
                <div className="product-add-info-form">
                  <UiDropDown
                    label="Показать на главной:"
                    items={[
                      { value: 1, text: "Скрыть" },
                      { value: 0, text: "Показать" },
                    ]}
                    onChange={(val) => updateOrderObj("is_site_menu", val)}
                  />
                </div>

                <div className="product-add-info-form product-add-info-photo">
                  <UiButtonColor
                    color="primary"
                    text={order.isEdit ? "Редактировать" : "Создать"}
                    small={true}
                    onClick={() => {
                      if (order.title != "") {
                        let _order = order;
                        props.addCategory(_order).then((res) => {
                          if (res) navigate(`/products/category/${res.id}`);
                        });
                      } else {
                        alert("Заполните поля название  ");
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(({ category }) => ({ category }), {
  addCategory: addCategoryAction,
})(ProductsCategoryAddPage);
